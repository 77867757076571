@import './base/bootstrap-extended/include'; // Bootstrap includes
@import './base/components/include'; // Components includes

// --- file input button for dark layout ----- //
.dark-layout {
    .form-control {
        &[type='file'] {
            &::file-selector-button {
                background-color: $theme-dark-input-bg;
            }
        }
    }
}

.ag-root-wrapper.ag-layout-normal {
    border: none;
}

.flatpickr-wrapp {
    position: relative;
}

.flatpickr-wrapp .clear-date {
    position: absolute;
    top: calc(50% - 9px);
    right: 10px;
    padding: 3px;
    line-height: 1;
    border: none;
    background: none;
    z-index: 6;
    font-size: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    height: 14px!important;
    left: 0px!important;
    margin-left: auto!important;
    margin-top: auto!important;
    position: absolute;
    top: 50%;
    width: 0;
    border: none!important;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #d8d6de!important;
    border-radius: 4px;
}
